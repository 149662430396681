import { css } from 'linaria';
import { theme } from '../../Theme';

export const smallSection = css`
  display: flex;
  flex-direction: column;
  padding: ${theme.space[1]};
  width: 30rem;
  max-width: 100%;
  label {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
  }

  ${theme.below.sm} {
    width: 100%;
  }
  h1 {
    text-align: center;
    font-size: 1.5rem;
  }
  p {
    text-align: center;
    margin-bottom: 1rem;
  }
`;

export const largeSection = css`
  display: flex;
  flex-direction: column;
  padding: ${theme.space[1]};
  width: 60rem;
  max-width: 100%;
  label {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
  }

  ${theme.below.sm} {
    width: 100%;
  }
`;

export const activeSegment = `
  position: relative;
`;

export const paddingBox = css`
  padding: 3rem;
  background: #f7f7f7;
  ${theme.below.lg} {
    padding: 1.5rem;
  }
`;
