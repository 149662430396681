import LogInFormProvider from '@jetshop/ui/Auth/LogInFormProvider';
import t, { Intl } from '@jetshop/intl';
import React, { useContext } from 'react';
import { styled } from 'linaria/react';
import { cx } from 'linaria';
import { Link } from 'react-router-dom';
import { useVoyadoLookup } from '@jetshop/flight-voyado';
import ChannelContext from '@jetshop/core/components/ChannelContext';
import useAuth from '@jetshop/core/components/AuthContext/useAuth';
import { Redirect } from 'react-router';
import { useShopConfig } from '@jetshop/core/hooks/useShopConfig';

import Input from '../../Forms/Input';
import MaxWidth from '../../Layout/MaxWidth';
import TrendButton from '../../ui/Button';
import { activeSegment, smallSection } from '../UI/Form';
import { theme } from '../../Theme';
import USPList from '../../ui/USPList';
import heroImage from '../../../img/NewLoginImg.jpg';
import { useQuery } from 'react-apollo';
import { UspListQuery } from '../../Layout/Footer/UspListQuery.gql';
import { extractUspList } from '../../../utils/extractUSPList';
import ClubHooksUSP from './ClubHooksUSP';
import { ExternalLookupField } from './VoyadoLookupField';
import { Above } from '@jetshop/ui/Breakpoints';
import { ReactComponent as ClubHooksLogo } from '../../MyPages/imgs/clubhooks_shield.svg';

const LoginWrapper = styled('div')`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr auto;

  ${theme.below.lg} {
    grid-template-columns: 1fr;
    grid-template-rows: 400px 1fr auto;
  }
  

  .hero-section {
    position: relative;
    grid-area: 1 / 1 / 3 / 2;
    display: flex;
    align-items: center;
    justify-content: center;

    ${theme.below.lg} {
      grid-area: 1 / 1 / 2 / 2;
    }

    .image-container {
      width: 100%;
      height: 100%;
      background-image: url(${heroImage});
      background-size: cover;      
      background-position: center center; /* Shows center-right portion */
      background-repeat: no-repeat;

      /* filter: contrast(1.2) saturate(1.25); */
    }

    .content {
      position: absolute;
      top: 30%;
      left: 50%;
      width: 250px;
      transform: translate(-50%, -50%);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .logo-container {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      ${theme.below.lg} {
        top: 50%;
        width: 200px;
      }
    }
  }

  .form-section {
    grid-area: 1 / 2 / 2 / 3;
    background: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: clamp(2rem, 4vw, 4rem);

    ${theme.below.lg} {
      grid-area: 2 / 1 / 3 / 2;
    }

    section {
      padding: 0 !important;
    }

    .header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    }

    h1.title {
      font-size: 40px;
      font-weight: 600;
      margin-bottom: .5rem;
      margin-top: 0;
    }

    .text {
      font-size: 14px;
      font-weight: 400;
      margin-bottom: 2rem;
      max-width: 350px;
    }
  }

  form {
    width: 100%;
  }

  input {
    border: none;
    height: 3rem;
    background-color: ${theme.colors.white};
    border-bottom: 1px solid ${theme.colors.darkgrey};
    padding: 0.4rem;
    &::placeholder {
      color: ${theme.colors.grey};
      font-size: 14px;
      font-weight: 300;
      opacity: 0.7;
    }

    // For older browsers
    &::-webkit-input-placeholder { /* Chrome/Safari/Opera */
      color: ${theme.colors.grey};
      font-size: 14px;
      opacity: 0.7;
    }
    &::-moz-placeholder { /* Firefox */
      color: ${theme.colors.grey};
      font-size: 14px;
      opacity: 0.7;
    }
    &:-ms-input-placeholder { /* IE/Edge */
      color: ${theme.colors.grey};
      font-size: 14px;
      opacity: 0.7;
    }
  }
  
  .login-error-message {
    color: red;
    font-size: 12px;
    margin: -1rem 0 1rem 0;
  }

  .club-hooks-section {
    grid-area: 2 / 2 / 3 / 3;
    width: 100%;
    height: 100%;
    ${theme.below.lg} {
      grid-area: 3 / 1 / 4 / 2;
    }
  }

  label {
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0.05em;
  }
`;

const TextLink = styled(Link)`
  text-decoration: underline;
  color: ${theme.colors.black} !important;
  text-align: left;
  font-size: 0.875em;
  align-self: flex-start;
  margin: ${props => props.globalError ? '-.5rem 0 1rem 0' : '-.5rem 0 1rem 0'};
  text-underline-offset: 4px;
  text-decoration-thickness: 1px;
  text-decoration-color: ${theme.colors.grey};

  :hover {
    opacity: 0.8;
  }
  &.signup-link {
    margin-left: auto;
  }
`;

export const LogInPageMaxWidth = styled(MaxWidth)`
  align-items: center;
  justify-content: flex-start;
  margin: auto;
  section.loginSection {
    padding: 0 3rem;
    width: 100%;
    max-width: 30rem;
  }
  & > form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  svg {
    max-width: 20rem;
    margin-bottom: 2rem;
    .hf-2 {
      fill: black !important;
    }
  }
  ${theme.below.sm} {
    padding: 4rem 1rem !important;
  }
  .suggest {
    margin-top: -1.5rem;
    padding: 0.5rem;
    margin-bottom: 1.5rem;
    font-size: 13px;
    span {
      text-decoration: underline;
      cursor: pointer;
    }
  }
`;

const LoginPageHeader = styled('header')`
  display: flex;
  align-items: center;
  margin-bottom: 0.75rem;
`;

export const CustomerStatusHeaderText = styled('h4')`
  margin-bottom: 0.5rem;
  font-style: normal;
  font-weight: 400;
  &.activation-text {
  max-width: 500px;
  background-color: #fff;
  padding: 1rem;
  border-radius: 5px;
  }
`;

const country = {
  NO: t('Norge'),
  SE: t('Sverige')
};

const HeaderText = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: 600;
`;

 export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 10px;
  width: 100%;
  gap: 1rem;
  button {
    border-radius: 3px;
    position: relative;
    overflow: hidden;
    &:hover {
      background-color: ${theme.colors.accent} !important;
      color: #fff;
    }
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: -100%;
      width: 50%;
      height: 100%;
      background: linear-gradient(
        120deg,
        transparent,
        rgba(255, 255, 255, 0.4),
        transparent
      );
      transition: 0.5s;
    }
    
    &:hover::before {
      left: 100%;    }
  }
`;
export const StyledTrendButton = styled(TrendButton)`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 100%;
  height: 41px;
  background: ${theme.colors.accent};
  color: ${theme.colors.white};
  font-size: 14px;
`;

export const LinkToSignUp = styled('a')`
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  height: 41px;
  width: 100%;
  font-size: 14px;
  font-weight: 600;
  border: 1px solid ${theme.colors.darkgrey};
  border-radius: 3px;
  color: ${theme.colors.black} !important;
  
`;

const LookupWrapper = ({ children, className }) => (
  <LoginWrapper>
    {children}
  </LoginWrapper>
);

const checkIfLookupHasRun = (voyado) => {
  return Object.values(voyado).some(value => value === true);
}

function VoyadoLookup() {
  const { lookup, errors, ...voyado } = useVoyadoLookup({
    lookupOptions: {
      personLookupConfigured: true
    }
  });
  const { routes } = useShopConfig();
  const { selectedChannel } = useContext(ChannelContext);
  const { logIn, loggedIn } = useAuth();
  const lookupHasRun = checkIfLookupHasRun(voyado);

  const ErrorMessage = () => {
    try {
      if (voyado?.isPreExistingCustomer) {
        return (
          <p className="login-error-message">
            {t('Email och/eller lösenord matchar inte')}
          </p>
        );
      }
      if (voyado.isNonExistingCustomer || voyado.isPersonLookupFailed) {
        return (
          <p className="login-error-message">
            {t('Email och/eller lösenord matchar inte')}
          </p>
        );
      }
      return null; // Return null if no conditions are met
    } catch (error) {
      console.error('Error in ErrorMessage:', error);
      return null;
    }
  };


  if (loggedIn) {
    return <Redirect to={routes.myPages.path} />;
  }

  if (voyado.isAdditionalDataRequired) {
    return (
      <Redirect
        to={{
          pathname: routes.signup.path,
          state: {
            customer: {
              ...voyado.customer
            },
            status: {
              isAdditionalDataRequired: voyado.isAdditionalDataRequired
            }
          }
        }}
      />
    );
  }

  // if (voyado.isPersonLookupIdle || voyado.isPersonLookupPending) {
  //   return (
  //     <LookupWrapper className="max-width">
  //       <LookupFieldContainer>
  //         <CustomerStatusHeaderText>
  //           {t('Fyll i ditt personnummer')}
  //         </CustomerStatusHeaderText>
  //         <Formik
  //           initialValues={{ lookupKey: '' }}
  //           onSubmit={values => {
  //             voyado.personLookup(values.lookupKey);
  //           }}
  //         >
  //           {({ handleSubmit }) => (
  //             <form onSubmit={handleSubmit}>
  //               <Input
  //                 type="text"
  //                 label="PID"
  //                 name="lookupKey"
  //                 id="lookupKey"
  //               />
  //               <StyledTrendButton
  //                 type="submit"
  //                 disabled={voyado.isPersonLookupPending}
  //                 loading={voyado.isPersonLookupPending}
  //                 loadingText={t('Hold on a moment...')}
  //               >
  //                 {t('Hämta mina uppgifter')}
  //               </StyledTrendButton>
  //             </form>
  //           )}
  //         </Formik>
  //       </LookupFieldContainer>
  //     </LookupWrapper>
  //   );
  // }

  if (voyado.isNonExistingCustomer || voyado.isPersonLookupFailed) {
    return (
      <Redirect
        to={{
          pathname: routes.signup.path,
          state: {
            customer: { ...voyado?.customer, externalId: '' },
            status: {
              isNonExistingCustomer: voyado.isNonExistingCustomer
            }
          }
        }}
      />
    );
  }

  if (voyado.isCountryNotValid) {
    return (
      <LookupWrapper>
        <CustomerStatusHeaderText data-testid="country-not-valid-status">
          {t.rich(
            "You're trying to activate an account with country { countryCode } that doesn't exist in { market }. Try selecting a country that matches { countryCode } from our country selector.",
            {
              countryCode: country[voyado?.customer?.countryCode],
              market: selectedChannel.url
            }
          )}
        </CustomerStatusHeaderText>
      </LookupWrapper>
    );
  }

  if (voyado.isPersonLookupSuccess) {
    return (
      <Redirect
        to={{
          pathname: routes.signup.path,
          state: {
            customer: { ...voyado?.customer, externalId: '' },
            status: {
              isPersonLookupSuccess: voyado.isPersonLookupSuccess
            }
          }
        }}
      />
    );
  }

  if (voyado.isInActivation) {
    console.log('voyado.isInActivation', voyado.isInActivation)
    const { isActivationRequired, isActivationPending, isActivationSuccess, isActivationFailed } =
      voyado;
    return (
      <LoginWrapper show={t('Show')} hide={t('Hide')}>
      
      <section className='hero-section' aria-label='Club Hööks Hero'>
        <div className="image-container">
          
        </div>
        <div className="content">
          <div className="logo-container">
            <ClubHooksLogo />
          </div>
        </div>
      </section>

      <section className="form-section">
        <div className="content">
          <header className="header">
            <h1 className="title">{t('Activate your account')}</h1>
            <CustomerStatusHeaderText
                data-testid={isActivationSuccess && 'successfully-activated'}
                >
                {isActivationRequired &&
                  t(
                    'You exist as an member, click to activate your account. We will send you an email with a link on how to set your password.'
                  )}
                {isActivationSuccess &&
                  t('We have successfully activated your account.')}
                {isActivationPending && t('Activating account')}
                {isActivationFailed && t('Please contact support')}
            </CustomerStatusHeaderText>        
          </header>

        <ButtonContainer>
          <StyledTrendButton
            data-testid="commit-activation"
            disabled={isActivationPending}
            loading={isActivationPending}
            onClick={() => {
              if (isActivationRequired) {
                voyado.activate();
              } else {
                logIn(voyado.customer.token);
              }
            }}
          >
            {isActivationRequired && t('Activate')}
            {isActivationPending && t('Activating')}
            {isActivationSuccess && t('Login')}
            {isActivationFailed && t('Activation failed')}
          </StyledTrendButton>
      </ButtonContainer>
        </div>
      </section>
      <Above breakpoint="lg">
        {matches =>
          matches && (
            <section className="club-hooks-section">
              <ClubHooksUSP  />
            </section>
          )
        }
      </Above>
      </LoginWrapper>
    );
  }

  // if (voyado.isPreExistingCustomer) {
  //   console.log('isPreExistingCustomer');
  //   return (
  //     <LookupWrapper>
  //       {/* <Intl>{t => <Head data={{ title: t('Login') }} />}</Intl> */}
  //       <LogInFormProvider
  //         redirect="my-pages"
  //         initialEmail={voyado?.customer?.emailAddress?.masked}
  //       >
  //         {({ globalError, isSubmitting, isValid }) => (
  //           <section
  //             className={cx(smallSection, activeSegment, 'loginSection')}
  //           >
  //             <Input type="email" name="email" label={t('E-mail address')} />
  //             <Input type="password" name="password" label={t('Password')} />

                {/* {globalError && (
                  <GlobalError style={{ marginBottom: '2em' }}>
                    {globalError}
                  </GlobalError>
                )} */}

              {/* <section className={actionWrapper}>
                <StyledTrendButton
                  type="submit"
                  data-testid="login-button"
                  disabled={!isValid}
                  loading={isSubmitting}
                  loadingText={t('Hold on a moment...')}
                >
                  {t('Log in')}
                </StyledTrendButton>
                <TextLink to={routes.forgotPassword.path} className='forgot-password'>
                  {t('Forgot password?')}
                </TextLink>
              </section> */}
  //           </section>
  //         )}
  //       </LogInFormProvider>
  //     </LookupWrapper>
  //   );
  // }


  const clubHooksTitle = t('Bli medlem');
  const clubHooksText = t('Som medlem får du ta del av massa förmåner, går snabbt genom kassan och får enkelt tillgång till tidigare beställningar. Registrera dig nedan, det är enkelt och kostar inget.');
  const uspListTitle = t('Du får bland annat:');
  

  //DEV note  
  //h1 is set at 'login' in the form section for SEO reasons as this is the page title and describes the page
  return (
    <LoginWrapper show={t('Show')} hide={t('Hide')}>
      
      <section className='hero-section' aria-label='Club Hööks Hero'>
        <div className="image-container">
          
        </div>
        <div className="content">
          <div className="logo-container">
            <ClubHooksLogo />
          </div>
        </div>
      </section>

      <section className="form-section">
        <div className="content">
          <header className="header">
            <h1 className="title">{t('Log in')}</h1>
            { !voyado.isPreExistingCustomer ? <p className="text">{t(`Enter your email in the field below to check if you are registered as a member.`)}</p> : <p className="text">{t('You exist as a member, enter your password to login.')}</p>}
        </header>
        { !voyado.isPreExistingCustomer ? (
          <>
            <ExternalLookupField lookup={lookup} isExternalLookupPending={voyado.isExternalLookupPending} />
      
            {(Object.values(voyado.error).some(Boolean) ||
              voyado.isCountryNotValid) && (
              <p>{t('Something went wrong. Please try again')}</p>
            )}
          </>
            ):(
          <LogInFormProvider
              redirect="my-pages"
              initialEmail={voyado?.customer?.emailAddress?.masked}
            >
              {({ globalError, isSubmitting, isValid, values }) => (
                <section
                  className={cx(smallSection, activeSegment, 'loginSection')}
                >
                  <Intl>
                    {t => (
                      <>
                  <Input 
                    type="email" 
                    name="email" 
                    label={t('E-mail address')} 
                    placeholder={t('Write your email here')}
                  />
                  <Input 
                    type="password" 
                    name="password" 
                    label={t('Password')} 
                    placeholder={t('Write your password here')}
                  />
                  </>
                  )}
                  </Intl>
                  {globalError && lookup(values?.email)}
                  {globalError && voyado && <ErrorMessage />}
                  
                  <TextLink to={routes.forgotPassword.path} globalError={globalError}
                    data-testid="forgot-password"
                  >
                    {t('Forgot password')}?
                  </TextLink>

                    <ButtonContainer>
                      <StyledTrendButton
                        type="submit"
                        data-testid="login-button"
                        disabled={!isValid}
                        loading={isSubmitting}
                        loadingText={t('Hold on a moment...')}
                        
                      >
                        {t('Log in')}
                      </StyledTrendButton>
                      {/* <LinkToSignUp 
                        href={routes.signup.path}
                        aria-label={t('Become a member')}
                      >
                        {t('Become a member')}
                      </LinkToSignUp> */}
                    </ButtonContainer>
                </section>
              )}
          </LogInFormProvider>
        )}
        </div>
      </section>
      <Above breakpoint="lg">
        {matches =>
          matches && (
            <section className="club-hooks-section">
              <ClubHooksUSP  />
            </section>
          )
        }
      </Above>      
    </LoginWrapper>
  );
}

export default VoyadoLookup;
