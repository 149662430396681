import t from 'format-message';
import React from 'react';
import { styled } from 'linaria/react';
import { theme } from '../../Theme';
import { useQuery } from 'react-apollo';
import { UspListQuery } from '../../Layout/Footer/UspListQuery.gql';
import { extractUspList } from '../../../utils/extractUSPList';
import ListOfUsps from '../../ui/ListOfUsps';

const ClubHooksWrapper = styled('div')`
    grid-area: 2 / 2 / 3 / 3;
    background: ${theme.colors.background};
    display: flex;
    align-items: flex-start;
    justify-content: center;
    height: fit-content;
    padding: 4rem 0;

    ${theme.below.lg} {
      grid-area: 3 / 1 / 4 / 2;
      padding: 2rem;
      height: 100%; 
    }

    .content {
      max-width: 450px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
    }

    .title {
      font-size: 24px;
      font-weight: 600;
      margin-bottom: .5rem;
    }

    .text {
      font-size: 16px;
      font-weight: 400;
      margin-bottom: 2rem;
    }

    .list-title {
      text-transform: uppercase;
      font-size: 14px;
      font-weight: 700;
      letter-spacing: 0.05em;
      margin-bottom: .5rem;
    }

  label {
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0.05em;
  }
`;

const ClubHooksUSP = () => {

const { data } = useQuery(UspListQuery, {
    variables: { id: 3644 },
    });
const uspList = extractUspList(data);

    return (
    <ClubHooksWrapper>
        <div className="content">
          <h2 className="title">{t('Become a member')}</h2>
          <p className="text">{t("As a member, you get to enjoy many benefits, go quickly through checkout, and easily access previous orders. Register below, it's easy and costs nothing.")}</p>
          <p className="list-title">
            {t('You get the following benefits:')}
          </p>
          {uspList && <ListOfUsps uspList={uspList} />}
        </div>
      </ClubHooksWrapper>
    );
}

export default ClubHooksUSP;